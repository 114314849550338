// Deps
import 'lazysizes';

/** General event binding */
// internals.bindEvents = () => {
//   $(document).on('click', '.js-magnific-popup', () => {

//     const $contentToScroll = $('.js-modal-scroll');

//     if (browser.macosx && !browser.webkit) {
//       $contentToScroll.addClass('ios-firefox');
//     }

//     $contentToScroll.scrollbar({
//       scrollStep: 20
//     });
//   });
// };

const global = () => {
	// bindEvents();
};

export default global;
