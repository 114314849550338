// Local scope ==================================
const internals = {};

// Capitalize first letters in every word =======
internals.capPhrase = (phrase) => phrase.replace(/_/g, ' ').replace(/\b[a-z](?=[a-z]{2})/g, letter => letter.toUpperCase());

// Rand!! wee! ==================================
internals.rand = () => (Math.random().toString(36) + '00000000000000000').slice(2, 8 + 2);

/* eslint-disable */
// Taken from David Walsh's blog
// https://davidwalsh.name/javascript-debounce-function
/**
 * Debounce/Throttle function
 * @param  {Function} func - callback that will be executed
 * @param  {Number}   wait - milliseconds to wait until the callback gets executed
 * @param  {Boolean}  immediate - If passed, trigger the callback on the leading edge, instead of the trailing
 * @return {Function} a function, that, as long as it continues to be invoked, will not be triggered
 */
internals.debounce = (func, wait, immediate) => {

  let timeout;

  return function() {

    const context = this;
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) {
        func.apply(context, args);
      }
    };
    const callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) {
      func.apply(context, args);
    }
  };
};
/* eslint-enable */

// Export utils =================================
export default internals;
