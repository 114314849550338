// Deps
import 'babel-polyfill';
import 'formdata-polyfill';
import 'whatwg-fetch';
import $ from 'jquery';

// Utilities
import _ from './modules/utils';

// Globals
import runGlobal from './modules/global';

// Assigning global variables to window object
Object.assign(window, { $, jQuery: $, _ });

// STOP IE11 and other old browsers from caching page state
window.onpageshow = (event) => {
  if (event.persisted) {
    window.location.reload();
  }
};

// Kickstart globals
runGlobal();

